.map-card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.25);
    width: 400px;
    height: 40vh;
    border-radius: 10px;
    background-color: white;
    margin-top: 20px;
    padding: 0px 20px 20px 20px;
}