.container {
    box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    padding: 30px;
    width: 90%;
    background-color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 40px;
    border-radius: 10px;
}
