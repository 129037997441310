.navbar {
    position: relative;
    width: 100%;
    height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: white;
}

.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: var(--content-width);
}

.hamburger-menu {
    display: none;
    font-size: 30px;
    background: none;
    border: none;
    color: #23406c;
    cursor: pointer;
}

.navigation-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navigation-item {
    min-width: fit-content;
    font-weight: 600;
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    color: #23406c;
}

.navigation-item a {
    text-decoration: none;
}

.navigation-item:hover {
    color: #387db0;
    transition: all 0.4s ease 0s;
    cursor: pointer;
    /* transform: scale(1.2); */
}

.navigation-item:hover, .navigation-item.active {
    color: #387db0;
  }

.navbar-right {
    display: flex;
    align-items: center;
    margin-left: auto;
}

@media (max-width: 1260px) {
    .navbar-right {
        flex-direction: row;
        justify-content: flex-end;
    }

    .navigation-items {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 10px;
    }

    .navigation-items.open {
        display: flex;
    }

    .hamburger-menu {
        display: block;
    }

    .logo {
        margin-left: 10px;
    }

    .desktop-auth-button {
        display: none;
    }

    .language-selector {
        margin-left: auto;
    }
}

@media (min-width: 1260px) {
    .desktop-auth-button {
        display: flex;
    }

}
