.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    height:100px;
    background-image: linear-gradient(to right, #387db0, #2b517d);
    margin-left: calc(-50vw + 50%);
    color: white;
    flex-direction: row;
    flex-wrap: wrap;
}

.header-title {
    color: white;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}

@media (max-width: 920px) {
    .header-container {
        flex-direction: column;
        align-items: center;
    }
    .header-content {
        padding: 20px;
        text-align: center;
    }
    .header-image {
        padding: 20px;
    }
}
