.searchBar {
    display: flex;
    width: 100%;
    min-height: 6vh;
    box-sizing: border-box;

    border-radius: 10px;
    box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.25);
}

.input {
    border: none;
    width: 100%;
    padding: 0 25px;
    border-radius: 10px;
}
