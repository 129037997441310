.container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.link {
    cursor: pointer;
    text-decoration: underline;
}