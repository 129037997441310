.button {
    width: 150px;
    min-height: 5vh;
    background-color: white;

    border: 1px solid #f2f2f2;
    border-radius: 10px;
    box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.25);

    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
}

.button:hover {
    transition: all 0.4s ease 0s;
    cursor: pointer;
    transform: scale(1.2);
    filter: brightness(96%);
}

.selected {
    border: 1px solid #e4eaf6;
    background-color: #e6ecf8;

    box-shadow: 8px 13px 18px rgba(0, 0, 0, 0.30);
    transform: translateX(1px) translateY(1px);
}

.selected:hover {
    transition: all 0.4s ease 0s;
    cursor: pointer;
    transform: scale(1.2) translateX(1px) translateY(1px);
    filter: brightness(96%);
}
