.service-tile {
  display: flex;
  flex-direction: column;
}

.service-tile_header {
  display: flex;
  justify-content: space-between;
}

.service-tile_header_st_row {
  font-family: Titillium Web;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}

.service-tile_header_nd_row {
  font-family: Titillium Web;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.service-tile_header_details {
  font-family: Titillium Web;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #000094;
}

.service-tile_content {
  display: flex;
  flex-direction: row;
}

.div_hidden {
  display: none;
}

.service-tile_body {
  padding: 2em 2em;
  width:100%;
}

.service-tile_nav {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.service-tile_active_tab {
  border-bottom: 3px solid #000094;
}

.service-tile_nav_item a{
  text-decoration: none;
  color: #2a2a2a;
}

.service-tile_nav_item {
  font: 18px/1.33 "Titillium Web", Helvetica, Arial, serif;
  color: #2a2a2a;
  letter-spacing: 0.25px;
}
