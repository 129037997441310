.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.car__body {
    -webkit-animation: shake 0.2s ease-in-out infinite alternate;
    animation: shake 0.2s ease-in-out infinite alternate;
}
.car__line {
    transform-origin: center right;
    stroke-dasharray: 22;
    -webkit-animation: line 0.8s ease-in-out infinite;
    animation: line 0.8s ease-in-out infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.car__line--top {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.car__line--middle {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.car__line--bottom {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

@-webkit-keyframes shake {
    0% {
        transform: translateY(-1%);
    }
    100% {
        transform: translateY(3%);
    }
}

@keyframes shake {
    0% {
        transform: translateY(-1%);
    }
    100% {
        transform: translateY(3%);
    }
}
@-webkit-keyframes line {
    0% {
        stroke-dashoffset: 22;
    }
    25% {
        stroke-dashoffset: 22;
    }
    50% {
        stroke-dashoffset: 0;
    }
    51% {
        stroke-dashoffset: 0;
    }
    80% {
        stroke-dashoffset: -22;
    }
    100% {
        stroke-dashoffset: -22;
    }
}
@keyframes line {
    0% {
        stroke-dashoffset: 22;
    }
    25% {
        stroke-dashoffset: 22;
    }
    50% {
        stroke-dashoffset: 0;
    }
    51% {
        stroke-dashoffset: 0;
    }
    80% {
        stroke-dashoffset: -22;
    }
    100% {
        stroke-dashoffset: -22;
    }
}
