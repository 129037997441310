.provider-account-details {
    display: flex;
    flex-direction: column;
}

.account-pane-loginhistory {
    margin: auto;
}

.provider-account-details_tab {
    display: flex;
}

.provider-account-section1__image13 {
    width: 100%;
    height: 100%;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.25px;
    color: #1C0E15;
}

.provider-account-section1__highlights4 {
    font: 18px/1.33 "Titillium Web", Helvetica, Arial, serif;
    color: #4b4b4b;
    letter-spacing: 0.25px;
}

.provider-account-section1__highlights5 {
    font: 18px/1.33 "Titillium Web", Helvetica, Arial, serif;
    color: #2a2a2a;
    letter-spacing: 0.25px;
    padding: 0 0.2em;
}

.provider-account-details_tab_hr {
    border: 3px solid #000094;
    margin: 0 0 0 0;
}  

.provider-account-section1__highlights5  a{
    text-decoration: none;
    color: #2a2a2a;
}

.provider-account-section1__line2 {
    background-color: #e9e9e9;
}
.provider-account-section1__line2.layout {
    position: relative;
    height: 0;
    border-top: 0;
 }

.account-pane-details {
    display: flex;
    justify-content: center;
    margin: 3em 3em;

    font: 12px/1.33 "TeleNeo", Helvetica, Arial, serif;
    color: #4b4b4b;
    letter-spacing: 0.4px;
}

.account-pane-details-image {
    margin: 3em 0em;
}

.account-pane-details-body {
    display: flex;
    margin: 3em 3em;
}

.account-pane-details-body-flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.account-pane-details-attribute {
    margin: 1em 1em;
    text-align: left;
}

.account-pane-details-attribute-box {
    font: 15px/1.33 "Titillium Web", Helvetica, Arial, serif;
    color: #1c0e15;
    letter-spacing: 0.25px;
    text-align: left;
    padding: 0.4em 1em 0.4em 1em;

    background-color: white;
    border: 1px solid #e8e8e8;
    border-radius: 4px 4px 4px 4px;
    min-width: 100px
}

.account-pane-flex {
    display: flex;
    justify-content: space-between;
}
      
.account-pane-history-row {
    border-bottom: 1px solid black;
}

.account-pane-history-head {
  font-family: 'TeleNeo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #4B4B4B;
}

.account-pane-history-cell {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #1C0E15;
}
