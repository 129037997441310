.tab-element {
    box-shadow: 0px 5px 10px rgba(29, 36, 48, 0.15);
    border-radius: 4px;

    width: 140px;
    height: 64px;

    margin-right: 15px;
    text-align: center;
}

.tab-element-inside {
    background: #E5E5E5;
    color: #2A2A2A;

    /* Body Small Bold */
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 40px;
    /* identical to box height, or 133% */
    letter-spacing: 0.25px;

    /* Text/White */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-top: 12px;

}

.tab-element-inside-active {
    background: #000094;
    color: #FFFFFF;
}