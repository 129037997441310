.filterContainer {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 250px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

.checkboxContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

.label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: lighter;
    user-select: none;
  }

  .checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
