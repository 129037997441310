.container {
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 30vh;
    border-radius: 10px;
    background-color: white;
    padding: 0 20px 20px 20px;
    box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.25);
}

.title {
    align-items: flex-start;
    text-align: left;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.buttons button {
    width: 100%;
}
