.languageSelector {
  display: flex;
  gap: 4px;
}

.languageButton {
  padding: 6px 6px;
  border: 1px solid #2c3e50;
  background-color: #ffffff;
  color: #2c3e50;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
}

.languageButton.active {
  background-color: #2c3e50;
  color: #ffffff;
}

.languageButton:hover {
  background-color: #ecf0f1;
}
