.newCarLoader {
    display: flex;
    justify-content: center;
}

.car {
    width: 100px;
    height: 100px;
}

