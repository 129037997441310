/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.provider-account-section1__highlights71.layout {
  min-height: 20px;
}
.provider-account-main {
  display: flex;
  flex-direction: column;
  background-color: white; }

.provider-account-main.layout {
  position: relative;
  overflow: hidden; }

.provider-account-details {
  display: flex;
  flex-direction: column; }

.provider-account-details.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 70.14%;
  margin: 8px auto;
 }


.provider-account-section1__cover-group {
  display: flex;
  flex-direction: column; }

.provider-account-section1__cover-group.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 95.05%;
  margin: 154px 4.95% 0px 0%; }

.provider-account-section1__image13 {

  width: 100%;
  height: 100%;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  
  letter-spacing: 0.25px;
  
  /* greyscale/darker */
  
  color: #1C0E15;
 }

.provider-account-section1__highlights4 {
  font: 18px/1.33 "Titillium Web", Helvetica, Arial, serif;
  color: #4b4b4b;
  letter-spacing: 0.25px; }

.provider-account-section1__highlights4.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 5px 24px 13px; }

.provider-account-details_tab {
  display: flex; }

.provider-account-details_tab.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 16px 25px 0px;
}

.provider-account-details_tab_hr {
  border: 3px solid #000094;
  margin: 0 0 0 0;
}

.provider-account-section1__highlights5 {
  font: 18px/1.33 "Titillium Web", Helvetica, Arial, serif;
  color: #2a2a2a;
  letter-spacing: 0.25px; 
}

.provider-account-section1__highlights5  a{
  text-decoration: none;
  color: #2a2a2a;
}
.provider-account-section1__highlights5.layout {
  position: relative;
  flex: 0 0 auto;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin-block-end: 0px; 
 }

.provider-account-details_tab-spacer {
  flex: 0 1 20px;
}

.provider-account-section1__line2 {
  background-color: #e9e9e9; }

.provider-account-section1__line2.layout {
  position: relative;
  height: 0;
  border-top: 0;
  margin: 0px 33px 0px 25px; }

.provider-account-details_info {
  display: flex; }

.provider-account-details_info.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 78.81%;
  margin: 64px auto 1043px; }

.provider-account-details_info-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 143px; }

.provider-account-section1__flex3 {
  display: flex;
  flex-direction: column; }

.provider-account-section1__flex3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 0px 419px; }

.provider-account-section1__image14 {
  background: var(--src) center center/contain no-repeat; }

.provider-account-section1__image14.layout {
  position: relative;
  height: 143px; }

.provider-account-section1__block7 {
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 4px 4px; }

.provider-account-section1__block7.layout {
  position: relative;
  height: 17px;
  margin: 16px 32px 0px; }

.provider-account-section1__block8 {
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 4px 4px; }

.provider-account-section1__block8.layout {
  position: absolute;
  top: 0px;
  height: 17px;
  width: 93px;
  right: -14px; }

.provider-account-section1__text-body {
  display: flex;
  align-items: center;
  font: 700 14px/1.42 "Titillium Web", Helvetica, Arial, serif;
  color: #000094;
  letter-spacing: 0.25px; }

.provider-account-section1__text-body.layout {
  position: absolute;
  top: -1.5px;
  height: 20px;
  left: 0px;
  width: 91px; }

.provider-account-details_info-spacer {
  flex: 0 1 19px; }

.provider-account-details_info-item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 307px; }

.provider-account-section1__flex4 {
  display: flex;
  flex-direction: column; }

.provider-account-section1__flex4.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 0px 78px; }

.provider-account-details-title1 {
  font: 24px/1.5 "Titillium Web", Helvetica, Arial, serif;
  color: black;
  letter-spacing: 0.25px; }

.provider-account-details-title1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content; }

.provider-account-section1__block {
  display: flex;
  flex-direction: column; }

.provider-account-section1__block.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 24px 0px 0px 1px; }

.provider-account-section1__small-text-body {
  font: 12px/1.33 "TeleNeo", Helvetica, Arial, serif;
  color: #4b4b4b;
  letter-spacing: 0.4px; }

.provider-account-section1__small-text-body.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 1px 0px 0px; }


.provider-account-section1__cover-block {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px 4px 4px; }

.provider-account-section1__cover-block.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 3px 0px 0px; }

.provider-account-section1__highlights71 {
  font: 15px/1.33 "Titillium Web", Helvetica, Arial, serif;
  color: #1c0e15;
  letter-spacing: 0.25px; }

.provider-account-section1__highlights71.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 8px 12px; }

.provider-account-details_info-spacer1 {
  flex: 0 1 21px; }

.provider-account-details_info-item2 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 306px; }

.provider-account-section1__flex5 {
  display: flex;
  flex-direction: column; }

.provider-account-section1__flex5.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content; }



  .provider-account-edit__flex {
    display: flex;
    flex-direction: column; }
  
  .provider-account-edit__flex.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 79%;
    margin: 50px auto; }
  
  .provider-account-edit__block9 {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #e9e9e9;
    border-radius: 0px 0px 4px 4px; }
  
  .provider-account-edit__block9.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 100%;
    margin: 0px 0% 0px 0px; }
  
  .margin_left_auto {
      margin-left: auto;
  }

