.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #23406c;
    padding: 10px;
    min-height: 7vh;
    max-width: 100%;
}

.footer-container p {
    font-weight: 600;
    color: white;
    padding-right: 10px;
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
}

.copyright {
    font-size: 15px;
}

.navigation-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.navigation-item {
    min-width: fit-content;
    font-weight: 600;
    font-size: 15px;
    padding: 10px;
    color: white;
}

.navigation-item a {
    text-decoration: none;
    color: white;
}

.navigation-item:hover {
    color: #A01441;
    transition: all 0.4s ease 0s;
    cursor: pointer;
}

@media (max-width: 670px) {
    .footer-container {
        flex-direction: column;
        align-items: center !important;
        align-items: flex-start;
    }

    .logo {
        padding-left: 0;
        margin-bottom: 10px;
    }

    .navigation-items {
        flex-direction: column;
        padding: 0;
    }

    .navigation-item {
        padding: 5px 0;
    }
}
