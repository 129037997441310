.details-page-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: auto;
}

.new-car-loader {
    display: flex;
    justify-content: center;
}